<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="130px" style="overflow-x:hidden;overflow-y:auto;">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名" auto-complete="off"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="sex">
            <el-select v-model="form.sex" placeholder="请选择性别" style="width:100%;">
              <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="出生日期" prop="birthDay">
            <el-date-picker
                v-model="form.birthDay"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="身份证号" prop="identityCards">
            <el-input v-model="form.identityCards" placeholder="请输入身份证号" auto-complete="off"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="学历" prop="education">
            <el-select v-model="form.education" placeholder="请选择学历" style="width:100%;">
              <el-option
                  v-for="item in educationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式" prop="contTel">
            <el-input v-model="form.contTel" placeholder="请输入联系方式" @blur="changeUseAccount" auto-complete="off"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="职称" prop="jobTitle">
            <vxe-select v-model="form.jobTitle" placeholder="请选择职称" style="width:100%;" @change="ChangeJobTitle">
              <vxe-option
                  v-for="(item, index) in jobTitleList"
                  :key="index"
                  :label="item.dictDesc"
                  :value="item.dictDesc"
              />
            </vxe-select>
            <!-- <el-input v-model="form.jobTitle" placeholder="请输入职称" auto-complete="off"></el-input> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="从业年限">
            <el-input
                v-model="form.workYears"
                placeholder="请输入从业年限"
                auto-complete="off"
                @input="(v) => (form.workYears = v.replace(/[^\d]/g, ''))"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="从业资质">
            <el-input v-model="form.professionalQualification" placeholder="请输入从业资质" auto-complete="off"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" v-if="showUser">
        <el-col :span="8">
          <el-form-item label="账号" prop="userAccount">
            <el-input v-model="form.userAccount" placeholder="请输入账号（默认使用手机号）" auto-complete="off"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="密码" prop="userPwd">
            <el-input v-model="form.userPwd" placeholder="请输入密码" auto-complete="off"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="16" v-if="form.jobTitle === '坐席'">
          <el-form-item label="坐席编号">
            <el-input v-model="form.webCallEmpNo" placeholder="请输入坐席编号" auto-complete="off" style="width:50%"/>
            <span style="color:red;margin-left:10px;">坐席必须填写,填写呼叫中心中对应员工编号</span>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="卡号">
            <el-input v-model="form.cardNum" placeholder="请输入卡号" auto-complete="off" style="width:50%"/>
            <!-- <span style="color:red;margin-left:10px;">坐席必须填写,填写呼叫中心中对应员工编号</span> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="16">
          <el-form-item label="服务单位">
            <el-input
                v-model="form.serviceSiteName"
                placeholder="请选择服务单位"
                auto-complete="off"
                :disabled="true"
                style="width:80%"
            >
              <el-button slot="append" type="primary" icon="el-icon-search" @click="selectServiceCompany()"/>
            </el-input>
            <span style="color:red;margin-left:5px;">请点击右侧按钮</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="所属区域" prop="tenant">
            <region-form ref="regionForm" v-model="form.tenant"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="0" prop="address">
            <el-input v-model="form.address" placeholder="请输入详细地址" auto-complete="off"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="经度">
            <el-input v-model="form.longitude" placeholder="请输入经度" auto-complete="off"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="纬度">
            <el-input v-model="form.latitude" placeholder="请输入纬度" auto-complete="off"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="状态">
            <vxe-select v-model="form.workPersonStatus" placeholder="请选择状态" style="width:100%;">
              <vxe-option
                  v-for="(item, index) in workPersonStatusList"
                  :key="index"
                  :label="item.dictDesc"
                  :value="item.dictPidVal"
              />
            </vxe-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="外勤打卡次数">
            <el-input
                v-model="form.fieldPunchMaxTimes"
                placeholder="请输入外勤打卡次数"
                auto-complete="off"
                @input="(v) => (form.fieldPunchMaxTimes = v.replace(/[^\d]/g, ''))"
            />
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item label="剩余次数">
            <el-input
                v-model="form.fieldPunchRemainTimes"
                placeholder="请输入剩余次数"
                auto-complete="off"
                disabled="disabled"
                @input="(v) => (form.fieldPunchRemainTimes = v.replace(/[^\d]/g, ''))"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="已使用次数" v-if="routeType !=='add'">
            <el-input
                v-model="form.usedFieldPunchTimes"
                placeholder="已使用次数"
                auto-complete="off"
                disabled="disabled"
                @input="(v) => (form.fieldPunchRemainTimes = v.replace(/[^\d]/g, ''))"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="身份证照片" prop="scannedContract">
            <upload-img :limit="2" :upload-lists="scannedContractList" @uploadChange="uploadSuccess3"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="资格证书" prop="insurancePolicy">
            <upload-img :limit="1" :upload-lists="insurancePolicyList" @uploadChange="uploadSuccess4"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="健康证" prop="healthReport">
            <upload-img :limit="1" :upload-lists="healthReportList" @uploadChange="uploadSuccess1"/>
          </el-form-item>
        </el-col>
        <!--        <el-col :span="12">-->
        <!--          <el-form-item label="核酸检测证明" prop="testCertificate">-->
        <!--            <upload-img :limit="1" :upload-lists="testCertificateList" @uploadChange="uploadSuccess2"/>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center;" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog title="选择服务单位" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
        <div>
          服务单位名称：
          <el-input
              v-model="searchData.orgName"
              style="width:200px;margin-right:10px;"
              size="medium"
              placeholder="请输入服务单位名称"
          />
          <el-button size="medium" type="primary" @click="search">搜索</el-button>
        </div>
        <div style="margin-top:10px;">
          <el-table ref="singleTable" :data="tableData" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                    v-model="radio"
                    :label="scope.$index"
                    style="color: #fff;padding-left: 10px; margin-right: -25px;"
                    @change.native="getCurrentRow(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="orgName" label="服务单位名称" width="200"/>
            <el-table-column prop="address" label="服务单位地址" width="396"/>
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
                background
                :current-page="tablePage.currentPage"
                :page-sizes="[5, 10, 15]"
                :page-size="tablePage.pageSize"
                layout="total,jumper,prev, pager, next,sizes"
                :total="tablePage.totalResult"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import RegionForm from '@/components/RegionForm.vue'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg,
    RegionForm
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() // 选当前时间之前的时间
        }
      },
      radio: '',
      height: 240,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      visible: false,
      searchData: {
        orgName: ''
      },
      visible: false,
      titleName: '',
      form: {
        birthDay: '1985-06-15',
        userAccount: '',
        userPwd: '123456'
      },
      showUser: true,
      healthReportList: [],
      testCertificateList: [],
      scannedContractList: [],
      insurancePolicyList: [],
      serveOrgList: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      streetList: [],
      communityList: [],
      jobTitleList: [], // 数据字典 职业，
      workPersonStatusList: [],
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        // cardNum: [{ required: true, message: '请输入卡号', trigger: 'blur' }],
        identityCards: [
          {
            pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: '请检查身份证号格式'
          }
        ],
        tenant: [{ required: true, message: '请选择所属区域', trigger: 'change' }],
        contTel: [{ required: true, message: '请输入联系方式', trigger: 'blur' }, {
          pattern: /^(1[3456789]\d{9})$/,
          message: '请检查负责人联系方式格式'
        }],
        jobTitle: [{ required: true, message: '请选择职称', trigger: 'change' }],
        userAccount: [{ pattern: /^(1[3456789]\d{9})$/, message: '请检查账号格式' }]
      },
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      educationList: [
        {
          value: '文盲及半文盲',
          label: '文盲及半文盲'
        },
        {
          value: '小学',
          label: '小学'
        },
        {
          value: '初中',
          label: '初中'
        },
        {
          value: '中专',
          label: '中专'
        },
        {
          value: '高中',
          label: '高中'
        },
        {
          value: '大专',
          label: '大专'
        },
        {
          value: '本科',
          label: '本科'
        },
        {
          value: '硕士及以上',
          label: '硕士及以上'
        }
      ]
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
    this.getProvincesList()
    this.getSysDictList('job', 'jobTitleList')
    this.workPersonStatus('workPersonStatus', 'workPersonStatusList')
  },
  methods: {
    ...mapActions([
      'downloadFile',
      'addWorkPersonServe',
      'updateWorkPersonServe',
      'getServeOrgsListServe',
      'detailWorkPersonServe',
      'getProvincesServe',
      'getCitysServe',
      'getAreasServe',
      'getStreetsServe',
      'getCommunitysServe',
      'getPersonOrgListServe',
      'getSysDictServe',
      'loginUserInfo'
    ]),

    // 获取数据字典
    getSysDictList(code, codeList) {
      const params = {
        typeCode: code
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this.jobTitleList = res.data
        }
      })
    },

    ChangeJobTitle() {
      this.showUser = this.form.jobTitle !== '坐席'
    },
    // 获取数据字典
    workPersonStatus(code, codeList) {
      const params = {
        typeCode: code
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.workPersonStatusList = res.data
        }
      })
    },
    initView() {
      // this.getServiceComanyInfo()
      if (this.routeType == 'add') {
        this.titleName = '添加从业人员'
        let comId = sessionStorage.comId
        let compName = sessionStorage.compName
        if (comId != null && compName != null && comId !== 'null' && compName !== 'null') {
          this.form = {
            birthDay: '1985-06-15',
            serviceSiteId: comId,
            userAccount: '',
            userPwd: '123456',
            serviceSiteName: compName
          }
        }
        this.rules['userAccount'].push({ required: true, message: '请输入联系方式', trigger: 'blur' })
        this.rules['userPwd'] = [{ required: true, message: '请输入密码', trigger: 'blur' }]
        // this.getCitysList('陕西省')
      } else {
        this.titleName = '修改从业人员'
        this.itemData = this.$route.query ? JSON.parse(this.$route.query.item) : null

        const params = {
          id: this.itemData.id
        }
        let data = null
        const server = 'detailWorkPersonServe'
        this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            console.log(data)
            this.form = {
              userAccount: this.itemData.userAccount,
              userPwd: '******',
              name: data.name,
              tenant: data.tenant.split(',').slice(2),
              birthDay: data.birthDay,
              sex: data.sex,
              contTel: data.contTel,
              jobTitle: data.jobTitle,
              workYears: data.workYears,
              serviceSiteId: data.serviceSiteId,
              serviceSiteName: data.compName,
              id: this.itemData.id,
              identityCards: data.identityCards,
              education: data.education,
              affiliatedUnit: data.affiliatedUnit,
              professionalQualification: data.professionalQualification,
              provice: data.provice,
              city: data.city,
              area: data.area,
              address: data.address,
              healthReport: data.healthReport,
              testCertificate: data.testCertificate,
              scannedContract: data.scannedContract,
              insurancePolicy: data.insurancePolicy,
              webCallEmpNo: data.webCallEmpNo,
              longitude: data.longitude,
              latitude: data.latitude,
              workPersonStatus: data.workPersonStatus,
              fieldPunchMaxTimes: data.fieldPunchMaxTimes,
              fieldPunchRemainTimes: data.fieldPunchRemainTimes,
              usedFieldPunchTimes: data.usedFieldPunchTimes
            }
            this.$nextTick(() => {
              this.$refs.regionForm.initRegion()
            })
            // if (data.provice) {
            //   this.getCitysList(data.provice)
            // }
            // if (data.city) {
            //   this.getAreasList(data.city)
            // }
            if (data.healthReport) {
              this.getFileDetail1(data.healthReport)
            }
            if (data.testCertificate) {
              this.getFileDetail2(data.testCertificate)
            }
            if (data.scannedContract) {
              this.getFileDetail3(data.scannedContract)
            }
            if (data.insurancePolicy) {
              this.getFileDetail4(data.insurancePolicy)
            }
          }
        })
      }
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.healthReportList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.testCertificateList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail3(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.scannedContractList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail4(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.insurancePolicyList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.healthReport = data.join(',')
    },
    uploadSuccess2(data) {
      this.form.testCertificate = data.join(',')
    },
    uploadSuccess3(data) {
      this.form.scannedContract = data.join(',')
    },
    uploadSuccess4(data) {
      this.form.insurancePolicy = data.join(',')
    },
    getProvincesList() {
      this.getProvincesServe().then((res) => {
        if (res.code == 200) {
          this.provinceList = res.data
        }
      })
    },
    provinceChange(val) {
      if (val) {
        this.getCitysList(val)
      }
    },
    getCitysList(val) {
      const params = {
        parenName: val
      }
      this.getCitysServe(params).then((res) => {
        if (res.code == 200) {
          this.cityList = res.data
        }
      })
    },
    cityChange(val) {
      if (val) {
        this.getAreasList(val)
      }
    },
    // 获取区县
    getAreasList(val) {
      const params = {
        parenName: val
      }
      this.getAreasServe(params).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetsList(val)
      }
    },
    // 获取街道
    getStreetsList(val) {
      const params = {
        parenName: val
      }
      this.getStreetsServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    streetChange(val) {
      if (val) {
        this.getCommunitysList(val)
      }
    },
    // 获取社区
    getCommunitysList(val) {
      const params = {
        parenName: val
      }
      this.getCommunitysServe(params).then((res) => {
        if (res.code == 200) {
          this.communityList = res.data
        }
      })
    },
    // 获取服务企业
    getServeOrgList() {
      const params = {
        current: 1,
        size: 99,
        param: {}
      }
      this.getServeOrgsListServe(params).then((res) => {
        if (res.code == 200) {
          this.serveOrgList = res.data.records
        }
      })
    },

    changeUseAccount() {
      if (typeof this.form.userAccount === 'undefined' || this.form.userAccount === '') {
        let reg = new RegExp(/^(1[3456789]\d{9})$/)
        let result = reg.test(this.form.contTel)
        if (result) {
          this.form.userAccount = this.form.contTel
        }
      }
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const server = this.routeType == 'add' ? 'addWorkPersonServe' : 'updateWorkPersonServe'
          const params = { ...this.form }
          let jobPidVal = ''
          this.jobTitleList.forEach((item) => {
            if (item.dictDesc === this.form.jobTitle) {
              jobPidVal = item.dictPidVal
            }
          })
          params.jobPidVal = jobPidVal
          params.tenant = `1,101,${this.form.tenant.join(',')}`
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    getCurrentRow(row) {
      this.form.serviceSiteId = row.orgId
      this.form.serviceSiteName = row.orgName
    },
    search() {
      this.getServiceComanyInfo()
    },
    handleSizeChange: function (size) {
      this.tablePage.pageSize = size
      this.getServiceComanyInfo()
    },
    // 控制页面的切换
    handleCurrentChange: function (currentPage) {
      this.tablePage.currentPage = currentPage
      this.getServiceComanyInfo()
    },
    getServiceComanyInfo() {
      const params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          orgName: this.searchData.orgName
        }
      }
      const server = 'getPersonOrgListServe'
      this[server](params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    selectServiceCompany() {
      this.visible = true
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;

  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }

  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;

    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
